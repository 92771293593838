import { initAjaxResetPasswordForm } from '../../ajax.js';

$(function() {       
    $('#login-form .haruki input').blur(function () {
        if ($(this).value != '') {
            $(this).parent().addClass('active');
        } else {
            $(this).parent().removeClass('active');
        }
    });
    $('.fa-eye').click(function () {
        $(this).css('display', 'none');
        $('.fa-eye-slash').css('display', 'block');
        document.getElementById('login-password').setAttribute('type', 'text');
    });
    $('.fa-eye-slash').click(function () {
        $(this).css('display', 'none');
        $('.fa-eye').css('display', 'block');
        document.getElementById('login-password').setAttribute('type', 'password');
    });

    initAjaxResetPasswordForm();
});
